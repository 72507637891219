
body {
  background-color: #ffffff; /* Replace #desiredColor with your chosen color code */
}

.hover-button:hover {
  background-color: #ff7f50ff; /* Your desired hover color */
  color: white; /* Text color on hover */
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  background-color: #3498db;
  padding: 20px;
}

/* Material UI handles most of the styling for buttons, typography, and containers */

/* Specific styles for custom components */
/* Add or adjust these styles based on your specific needs and Material UI integration */

.sign-up-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.sign-up-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.personality-traits-container, .interests-container, .relationship-container {
  text-align: center;
}

.row, .interests-row, .traits-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

/* Add any additional custom styles as needed */
